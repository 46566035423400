import Routes from './routes';
import './App.css';

import Locales from 'ui-component/Locales';
import ThemeCustomization from "./themes";
import NavigationScroll from "./layout/NavigationScroll";
import Snackbar from "./ui-component/extended/Snackbar";

import { RWProvider as AuthProvider } from 'contexts/RWContext';

const App = () => {
    return (
        <ThemeCustomization>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <Routes/>
                        <Snackbar/>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </ThemeCustomization>
    );
};

export default App;

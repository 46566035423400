// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconGauge,
  IconCalendarStats,
  IconUser,
  IconUsers,
  IconBuildingCastle,
  IconRouter
} from '@tabler/icons';

const other = {
  id: 'apps',
  title: <FormattedMessage id='apps' />,
  type: 'group',

  children: [
    {
      id: 'apps-overview',
      title: <FormattedMessage id='overview' />,
      type: 'item',
      url: '/analytics/apps',
      icon: IconGauge,
      breadcrumbs: true
    },
    {
      id: 'apps-timeline',
      title: <FormattedMessage id='timeline' />,
      type: 'item',
      url: '/analytics/apps/timeline',
      icon: IconCalendarStats,
      breadcrumbs: true
    },
    {
      id: 'apps-users',
      title: <FormattedMessage id='accounts' />,
      type: 'item',
      url: '/analytics/apps/users',
      icon: IconUser,
      breadcrumbs: true
    },
    {
      id: 'apps-profiles',
      title: <FormattedMessage id='profiles' />,
      type: 'item',
      url: '/analytics/apps/user-profiles',
      icon: IconUsers,
      breadcrumbs: true
    },
    {
      id: 'apps-projects',
      title: <FormattedMessage id='projects' />,
      type: 'item',
      url: '/analytics/apps/projects',
      icon: IconBuildingCastle,
      breadcrumbs: true
    },
    {
      id: 'apps-sessions',
      title: <FormattedMessage id='sessions' />,
      type: 'item',
      url: '/analytics/apps/sessions',
      icon: IconRouter,
      breadcrumbs: true
    }
  ]
};

export default other;

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from '../utils/axios';

// const
const initialState = {
  isLoggedIn: false,
  username: null,
  loginToken: null
};

/**
 * fixme Create verification for token
 */
const verifyToken = (loginToken) => {
  if (!loginToken) {
    console.error('Invalid token');
    return false;
  }

  return true;
};

const setSession = (loginToken) => {
  if (loginToken) {
    localStorage.setItem('login_token', loginToken);
    axios.defaults.headers.common.Authorization = `Bearer ${loginToken}`;
  } else {
    localStorage.removeItem('login_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUsername = (username) => {
  if (username) {
    localStorage.setItem('username', username);
  } else {
    localStorage.removeItem('username');
  }
};

// ==============================|| RW CONTEXT & PROVIDER ||============================== //

const RWContext = createContext(null);

export const RWProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const loginToken = window.localStorage.getItem('login_token');
        const username = window.localStorage.getItem('username');

        if (loginToken && verifyToken(loginToken)) {
          setSession(loginToken);
          setUsername(username);

          await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/robo-user/quick-login`, { login_token: loginToken });

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              username: username
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/robo-user/login`, { username, password });

    const { login_token } = response.data;

    setSession(login_token);
    setUsername(username);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        username
      }
    });
  };

  const logout = () => {
    setSession(null);
    setUsername(null);

    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <RWContext.Provider
      value={{
        ...state,
        login,
        logout
      }}
    >
      {children}
    </RWContext.Provider>
  );
};

RWProvider.propTypes = {
  children: PropTypes.node
};

export default RWContext;

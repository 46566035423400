import apps from './apps';
import production from './production';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [apps, production]
};

export default menuItems;

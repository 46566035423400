// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
      <svg width="125" height="32" viewBox="0 0 855 179" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M223.503 87.4069H236.049C238.094 87.4069 239.185 86.316 239.185 84.2706V57.9523C239.185 47.9978 245.867 44.4523 252.958 44.7251C254.594 44.7251 255.412 43.7705 255.412 41.725V26.9978C255.412 24.9523 254.322 23.5887 252.14 23.9977C246.003 24.9523 240.958 28.6341 239.185 34.9068V28.225C239.185 26.1796 238.094 25.0887 236.049 25.0887H223.503C221.458 25.0887 220.367 26.1796 220.367 28.225V84.4069C220.367 86.316 221.458 87.4069 223.503 87.4069Z" fill="#7E63B6"/>
              <path d="M291.682 89.1775C310.091 89.1775 324.682 74.8593 324.682 56.1774C324.682 37.6319 309.955 23.1774 291.682 23.1774C273.273 23.1774 258.682 37.4956 258.682 56.1774C258.546 74.7229 273.273 89.1775 291.682 89.1775ZM291.682 41.4501C299.728 41.4501 306.001 47.3138 306.001 56.1774C306.001 65.0411 299.728 70.9047 291.682 70.9047C283.637 70.9047 277.364 65.0411 277.364 56.1774C277.364 47.3138 283.501 41.4501 291.682 41.4501Z" fill="#7E63B6"/>
              <path d="M400.776 56.1819C400.776 37.6364 387.276 23.1819 370.913 23.1819C362.595 23.1819 356.458 26.0455 352.367 30.8182V3.13636C352.367 1.09091 351.276 0 349.231 0H336.685C334.64 0 333.549 1.09091 333.549 3.13636V84.2729C333.549 86.3184 334.64 87.4093 336.685 87.4093H349.231C351.276 87.4093 352.367 86.3184 352.367 84.2729V81.5457C356.595 86.3184 362.595 89.182 370.913 89.182C387.276 89.182 400.776 74.7274 400.776 56.1819ZM367.231 71.4547C358.64 71.4547 352.367 65.5911 352.367 56.1819C352.367 46.7728 358.64 40.9092 367.231 40.9092C375.822 40.9092 382.095 46.7728 382.095 56.1819C382.095 65.5911 375.822 71.4547 367.231 71.4547Z" fill="#7E63B6"/>
              <path d="M472.504 56.1774C472.504 37.6319 457.777 23.1774 439.504 23.1774C421.095 23.1774 406.504 37.4956 406.504 56.1774C406.504 74.7229 421.231 89.1775 439.504 89.1775C457.777 89.1775 472.504 74.7229 472.504 56.1774ZM439.504 70.9047C431.459 70.9047 425.186 65.0411 425.186 56.1774C425.186 47.3138 431.459 41.4501 439.504 41.4501C447.55 41.4501 453.822 47.3138 453.822 56.1774C453.822 65.0411 447.55 70.9047 439.504 70.9047Z" fill="#7E63B6"/>
              <path d="M300.82 112.232H287.32C285.411 112.232 284.32 112.914 283.775 114.823L274.638 148.914L264.002 114.823C263.457 113.05 262.229 112.232 260.457 112.232H250.775C248.593 112.232 247.502 113.596 248.184 115.778L252.138 128.187L245.047 151.096L235.366 114.959C234.82 113.05 233.593 112.369 231.82 112.369H218.32C216.138 112.369 215.047 113.732 215.729 115.914L233.729 172.232C234.275 174.005 235.638 174.823 237.411 174.823H241.502H249.411C251.184 174.823 252.547 174.005 253.093 172.232L259.638 151.505L266.32 172.232C266.866 174.005 268.229 174.823 270.002 174.823H271.502H282.002C283.775 174.823 285.138 174.005 285.684 172.232L303.684 115.914C304.093 113.596 303.002 112.232 300.82 112.232Z" fill="#7E63B6"/>
              <path d="M360.958 112.232H348.412C346.367 112.232 345.276 113.323 345.276 115.369V148.641C345.276 156.005 341.73 159.141 336.139 159.141C331.367 159.141 327.821 156.414 327.821 150.141V115.369C327.821 113.323 326.73 112.232 324.685 112.232H312.139C310.094 112.232 309.003 113.323 309.003 115.369V151.778C309.003 167.323 317.321 176.323 328.912 176.323C336.958 176.323 342.139 173.187 345.139 168.823V171.414C345.139 173.46 346.23 174.55 348.276 174.55H360.821C362.867 174.55 363.958 173.46 363.958 171.414V115.232C364.094 113.459 363.003 112.232 360.958 112.232Z" fill="#7E63B6"/>
              <path d="M411.412 110.576C403.503 110.576 398.184 113.712 395.184 118.076V115.485C395.184 113.44 394.094 112.349 392.048 112.349H379.503C377.457 112.349 376.366 113.44 376.366 115.485V171.667C376.366 173.712 377.457 174.803 379.503 174.803H392.048C394.094 174.803 395.184 173.712 395.184 171.667V138.394C395.184 131.031 398.73 127.894 404.321 127.894C409.094 127.894 412.639 130.621 412.639 136.894V171.667C412.639 173.712 413.73 174.803 415.775 174.803H428.321C430.366 174.803 431.457 173.712 431.457 171.667V135.258C431.321 119.44 423.003 110.576 411.412 110.576Z" fill="#7E63B6"/>
              <path d="M503.456 87.2804H491.047C489.001 87.2804 487.911 88.3713 487.911 90.4168V118.099C483.683 113.326 477.683 110.462 469.365 110.462C453.001 110.462 439.501 124.78 439.501 143.462C439.501 162.008 453.001 176.462 469.365 176.462C477.683 176.462 483.82 173.599 487.911 168.826V171.553C487.911 173.599 489.001 174.69 491.047 174.69H503.592C505.638 174.69 506.729 173.599 506.729 171.553V90.4168C506.592 88.5077 505.501 87.2804 503.456 87.2804ZM473.047 158.735C464.456 158.735 458.183 152.871 458.183 143.462C458.183 134.053 464.456 128.19 473.047 128.19C481.638 128.19 487.911 134.053 487.911 143.462C487.911 152.871 481.638 158.735 473.047 158.735Z" fill="#7E63B6"/>
              <path d="M549.276 110.576C529.503 110.576 515.867 124.894 515.867 143.576C515.867 162.122 529.094 176.576 549.821 176.576C562.503 176.576 572.73 170.985 577.64 160.076C578.594 158.031 577.367 156.531 575.185 156.531L563.185 156.667C561.549 156.667 560.594 157.349 559.367 158.303C557.185 160.212 553.639 161.44 550.094 161.44C543.549 161.44 537.821 158.44 535.503 151.076H561.958H577.23C579.14 151.076 580.367 150.258 580.64 148.212C580.776 146.712 580.912 145.212 580.912 143.576C580.776 124.621 567.549 110.576 549.276 110.576ZM535.367 136.758C537.276 130.485 542.185 127.212 549.139 127.212C554.594 127.212 560.185 129.667 562.094 136.758H535.367Z" fill="#7E63B6"/>
              <path d="M621.409 111.28C615.272 112.235 610.227 115.917 608.454 122.19V115.508C608.454 113.462 607.363 112.371 605.318 112.371H592.772C590.727 112.371 589.636 113.462 589.636 115.508V171.69C589.636 173.735 590.727 174.826 592.772 174.826H605.318C607.363 174.826 608.454 173.735 608.454 171.69V145.371C608.454 135.417 615.136 131.871 622.227 132.144C623.863 132.144 624.681 131.19 624.681 129.144V114.417C624.681 112.235 623.59 110.871 621.409 111.28Z" fill="#7E63B6"/>
              <path d="M667.641 143.464L686.05 116.055C687.414 113.873 686.596 112.373 684.005 112.373H669.686C668.05 112.373 666.823 113.055 666.004 114.419L649.913 139.51V90.5552C649.913 88.5097 648.823 87.4188 646.777 87.4188H634.232C632.186 87.4188 631.095 88.5097 631.095 90.5552V171.692C631.095 173.737 632.186 174.828 634.232 174.828H646.777C648.823 174.828 649.913 173.737 649.913 171.692V147.419L667.232 172.783C668.05 174.146 669.414 174.828 670.914 174.828H685.095C687.55 174.828 688.505 173.192 687.005 171.146L667.641 143.464Z" fill="#7E63B6"/>
              <path d="M709.503 112.232H696.957C694.912 112.232 693.821 113.323 693.821 115.369V171.55C693.821 173.596 694.912 174.687 696.957 174.687H709.503C711.548 174.687 712.639 173.596 712.639 171.55V115.369C712.639 113.459 711.412 112.232 709.503 112.232Z" fill="#7E63B6"/>
              <path d="M709.503 87.2804H696.957C694.912 87.2804 693.821 88.3713 693.821 90.4168V101.599C693.821 103.644 694.912 104.735 696.957 104.735H709.503C711.548 104.735 712.639 103.644 712.639 101.599V90.4168C712.639 88.5077 711.412 87.2804 709.503 87.2804Z" fill="#7E63B6"/>
              <path d="M759.68 110.576C751.635 110.576 746.453 113.712 743.453 118.076V115.485C743.453 113.44 742.362 112.349 740.317 112.349H727.771C725.726 112.349 724.635 113.44 724.635 115.485V171.667C724.635 173.712 725.726 174.803 727.771 174.803H740.317C742.362 174.803 743.453 173.712 743.453 171.667V138.394C743.453 131.031 746.998 127.894 752.589 127.894C757.362 127.894 760.908 130.621 760.908 136.894V171.667C760.908 173.712 761.998 174.803 764.044 174.803H776.589C778.635 174.803 779.726 173.712 779.726 171.667V135.258C779.726 119.44 771.271 110.576 759.68 110.576Z" fill="#7E63B6"/>
              <path d="M851.864 87.2804H839.318C837.273 87.2804 836.182 88.3713 836.182 90.4168V118.099C831.954 113.326 825.954 110.462 817.636 110.462C801.273 110.462 787.772 124.78 787.772 143.462C787.772 162.008 801.273 176.462 817.636 176.462C825.954 176.462 832.091 173.599 836.182 168.826V171.553C836.182 173.599 837.273 174.69 839.318 174.69H851.864C853.909 174.69 855 173.599 855 171.553V90.4168C855 88.5077 853.909 87.2804 851.864 87.2804ZM821.454 158.735C812.863 158.735 806.591 152.871 806.591 143.462C806.591 134.053 812.863 128.19 821.454 128.19C830.045 128.19 836.318 134.053 836.318 143.462C836.318 152.871 830.045 158.735 821.454 158.735Z" fill="#7E63B6"/>
              <path d="M66.0002 101.59H7.36366C3.27274 101.59 0 104.863 0 108.954V167.591C0 171.681 3.27274 174.954 7.36366 174.954H66.0002C70.0911 174.954 73.3638 171.681 73.3638 167.591V108.954C73.3638 104.863 70.0911 101.59 66.0002 101.59Z" fill="#7E63B6"/>
              <path d="M124.636 97.894C102.409 97.894 84.2727 115.894 84.2727 138.258C84.2727 160.485 102.273 178.621 124.636 178.621C146.864 178.621 165 160.621 165 138.258C165 116.03 146.864 97.894 124.636 97.894Z" fill="#7E63B6"/>
              <path d="M7.36365 86.9901H124.637C144.818 86.9901 161.319 70.6264 161.319 50.3082C161.319 30.1264 144.955 13.6263 124.637 13.6263H7.36365C3.27274 13.6263 0 16.8991 0 20.99V79.6264C0 83.7174 3.27274 86.9901 7.36365 86.9901ZM124.637 35.5809C132.682 35.5809 139.228 42.1264 139.228 50.1719C139.228 58.2173 132.682 64.7628 124.637 64.7628C116.591 64.7628 109.909 58.2173 109.909 50.1719C110.046 42.1264 116.591 35.5809 124.637 35.5809ZM95.4547 50.3082C95.4547 58.3537 88.9093 65.0355 80.7275 65.0355C72.682 65.0355 66.1365 58.49 66.1365 50.3082H95.4547ZM36.6819 35.5809C44.7274 35.5809 51.2728 42.1264 51.2728 50.1719C51.2728 58.2173 44.7274 64.7628 36.6819 64.7628C28.6364 64.7628 21.9546 58.2173 21.9546 50.1719C22.091 42.1264 28.6364 35.5809 36.6819 35.5809Z" fill="#7E63B6"/>
      </svg>

    );
};

export default Logo;

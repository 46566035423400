import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import {ConfigProvider} from './contexts/ConfigContext';
import App from './App';
import {BASE_PATH} from './config';

import {store} from './store';

import './index.css';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <BrowserRouter basename={BASE_PATH}>
                <App/>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

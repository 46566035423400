// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconGauge,
  IconCalendarStats,
  IconBuildingFactory,
  IconOutlet,
  IconPlayerStop,
  IconTestPipe,
  IconChartLine
} from '@tabler/icons';

const production = {
  id: 'production',
  title: <FormattedMessage id='production' />,
  type: 'group',

  children: [
    {
      id: 'production-overview',
      title: <FormattedMessage id='overview' />,
      type: 'item',
      url: '/analytics/production',
      icon: IconGauge,
      breadcrumbs: true,
    },
    {
      id: 'production-timeline',
      title: <FormattedMessage id='timeline' />,
      type: 'item',
      url: '/analytics/production/timeline/daily',
      icon: IconCalendarStats,
      breadcrumbs: true
    },
    {
      id: 'production-factories',
      title: <FormattedMessage id='factories' />,
      type: 'item',
      url: '/analytics/production/factories',
      icon: IconBuildingFactory,
      breadcrumbs: true
    },
    {
      id: 'production-modules',
      title: <FormattedMessage id='modules' />,
      type: 'item',
      url: '/analytics/production/physical-modules',
      icon: IconOutlet,
      breadcrumbs: true
    },
    {
      id: 'production-jigs',
      title: <FormattedMessage id='jigs' />,
      type: 'item',
      url: '/analytics/production/jigs',
      icon: IconPlayerStop,
      breadcrumbs: true
    },
    {
      id: 'production-test-modules',
      title: <FormattedMessage id='test_modules' />,
      type: 'item',
      url: '/analytics/production/modules',
      icon: IconTestPipe,
      breadcrumbs: true
    },
    {
      id: 'production-events',
      title: <FormattedMessage id='events' />,
      type: 'item',
      url: '/analytics/production/events',
      icon: IconChartLine,
      breadcrumbs: true
    }
  ]
};

export default production;

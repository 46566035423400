import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import DevRoutes from './DevRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const combinedRoutes = [MainRoutes, LoginRoutes, AuthenticationRoutes];

    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local' || process.env.REACT_APP_HOSTNAME === 'https://dev.analytics.robowunderkind.com') {
        combinedRoutes.push(DevRoutes);
    }

    return useRoutes(combinedRoutes);
}

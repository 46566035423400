import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

const Analytics = Loadable(lazy (() => import( '../Analytics')));
const AppAnalytics = Loadable(lazy (() => import( '../Analytics/AppAnalytics')));
const ProductionAnalytics = Loadable(lazy (() => import( '../Analytics/ProductionAnalytics')));
const MarketingAnalytics = Loadable(lazy (() => import( '../Analytics/MarketingAnalytics')));

const AcceptProject = Loadable(lazy (() => import( '../EmailActions/AcceptProject')));
const BlockUserConfirmation = Loadable(lazy (() => import( '../EmailActions/BlockUserConfirmation')));
const BlockUserResult = Loadable(lazy (() => import( '../EmailActions/BlockUserResult')));
const VerifyEmail = Loadable(lazy (() => import( '../EmailActions/VerifyEmail')));

const NotFound = Loadable(lazy (() => import( '../NotFound')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/analytics',
            element: <Analytics />
        },
        {
            path: '/analytics/apps/*',
            element: <AppAnalytics />
        },
        {
            path: '/analytics/production/*',
            element: <ProductionAnalytics />
        },
        {
            path: '/analytics/marketing/*',
            element: <MarketingAnalytics />
        },

        // fixme Place next routes somewhere else
        {
            path: '/verify-email/:hash',
            element: <VerifyEmail />
        },
        {
            path: '/accept-project/:hash',
            element: <AcceptProject />
        },
        {
            path: '/block-sharer/confirm/:hash',
            element: <BlockUserConfirmation />
        },
        {
            path: '/block-sharer/:hash',
            element: <BlockUserResult />
        },
        {
            path: '*',
            element: <NotFound />
        }

    ]
};

export default MainRoutes;
